import axios, { AxiosInstance, AxiosResponse } from "axios";
import { merchantListKycResponse, MerchantLocationlistkyc, RiderKycList,RiderDetailsKyc } from "../@Types/DRO/ApiResponses";

export class RiderKycApi {
  public static api = new RiderKycApi();
  private instance: AxiosInstance;
  private instanceurl: AxiosInstance;


  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/mobycy/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
    this.instanceurl = axios.create({
      baseURL: "https://dash.bcykal.com/",
     
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  

  riderDetailTrackingList(
    searchVal?: string | null,
    page?: number | null,
    rowsPerPage?: number | null,
    
  ): Promise<RiderKycList> {
    let apiUrl = "v1/create/client/request/list";
    if (searchVal) {
      apiUrl += `?search=${searchVal}`;
    }
    return this.instance
      .get<RiderKycList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: rowsPerPage,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  merchantListHyperTrack(
    userId?: number | null,
  ): Promise<merchantListKycResponse> {
    let apiUrl = "api/v1/dashboard/merchants";
    apiUrl +=`?userId=${userId}`;
    return this.instanceurl
      .get<merchantListKycResponse>(apiUrl, {
       
      })
      .then((e) => {
        return e.data;
      });
  }
  merchantStoreListHyperTrack(
    riderDetailsMerchantId?: number | null,
    
  ): Promise<MerchantLocationlistkyc> {
    let apiUrl = "v1/merchant/store/list";
    if (riderDetailsMerchantId) {
      apiUrl +=`?merchantId=${riderDetailsMerchantId}`;
    }
    return this.instance
      .get<MerchantLocationlistkyc>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  riderDetailsKyc(
    id?: number | null,
    
  ): Promise<RiderDetailsKyc> {
    let apiUrl = "v1/userDetail/userId";
    if (id) {
      apiUrl +=`?id=${id}`;
    }
    return this.instance
      .get<RiderDetailsKyc>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

}
