import axios, { AxiosInstance, AxiosResponse } from "axios";
import { TeamAndSpocsResponse } from "../@Types/DRO/ApiResponses";

export class TeamAndSpocsApi {
  public static api = new TeamAndSpocsApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/mobycy/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  teamAndSpocs(city?: string): Promise<TeamAndSpocsResponse> {
    let apiUrl = "get/data/team-and-spocs";
    if (city) {
      apiUrl += `${city}`;
    }

    return this.instance
      .get<TeamAndSpocsResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
}
